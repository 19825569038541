import { Injectable } from '@angular/core';
import { CapabilityStore } from './capability.store';
import {
  CapabilityModel,
  ICapability,
  SupplierListModel,
  VendorListModel
} from 'sustainment-component';
import { CapabilityApi } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
export class CapabilityActions {
  public constructor(
    private capabilityAPI: CapabilityApi,
    private store: CapabilityStore
  ) {}

  public getCapabilityData(): void {
    if (!this.store.getValue().industryList)
      this.capabilityAPI
        .getCapabilityData()
        .subscribe((capability) =>
          this.store.update(() => ({
            processList: capability.processList,
            industryList: capability.industryList,
            materialList: capability.materialList,
            classificationList:
              'classificationList' in capability
                ? (capability.classificationList as ICapability[])
                : [],
            certificationList: capability.certificationList as ICapability[],
            serviceList:
              'servicesList' in capability ? capability.servicesList : []
          }))
        );
  }

  public mapVendorIndustries(vendor: VendorListModel): VendorListModel {
    if (vendor?.industries && vendor?.industries.length > 0) {
      vendor.industries = this.mapIndustryNames(vendor.industries);
    }

    return vendor;
  }

  public mapVendorProfileIndustries(
    vendor: SupplierListModel
  ): SupplierListModel {
    if (vendor?.industries && vendor?.industries.length > 0) {
      vendor.industries = this.mapVendorIndustryNames(vendor.industries);
    }

    return vendor;
  }

  public getProcessByCode(code: string): ICapability | null {
    const list = this.store.getValue().processList;

    return list?.find((x) => x.code == code) || null;
  }

  public getIndustryByCode(code: string): ICapability | null {
    const list = this.store.getValue().industryList;

    return list?.find((x) => x.code == code) || null;
  }

  public mapProcessNames(processes: ICapability[]): ICapability[] {
    processes.forEach((proc) => {
      const fullProc = this.getProcessByCode(proc.code);
      if (fullProc) proc.name = fullProc.name;
    });

    const filteredProcesses = processes.filter((p) => p.name !== null);
    return filteredProcesses;
  }

  public mapIndustryNames(industries: CapabilityModel[]): CapabilityModel[] {
    industries.forEach((ind) => {
      const fullInd = this.getIndustryByCode(ind.code);
      if (fullInd) ind.name = fullInd.name;
    });

    //if name still == null, remove them from the array since they will break UI components
    const filteredIndustries = industries.filter((i) => i.name !== null);
    return filteredIndustries;
  }

  public mapVendorIndustryNames(
    industries: CapabilityModel[]
  ): CapabilityModel[] {
    industries.forEach((ind) => {
      const fullInd = this.getIndustryByCode(ind.code);
      if (fullInd) ind.name = fullInd.name;
    });

    //if name still == null, remove them from the array since they will break UI components
    const filteredIndustries = industries.filter((i) => i.name !== null);
    return filteredIndustries;
  }
}
