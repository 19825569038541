import { Component, OnDestroy, OnInit, signal, effect } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import {
  EMPTY,
  forkJoin,
  from,
  Observable,
  of,
  Subscription,
  combineLatest,
  timer
} from 'rxjs';
import {
  catchError,
  delay,
  filter,
  first,
  map,
  mergeMap,
  shareReplay,
  skip,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { CapabilityActions } from 'src/app/store/capability/capability.action';
import { CapabilityQuery } from 'src/app/store/capability/capability.query';
import { StateActions } from 'src/app/store/state/state.action';
import { StateQuery } from 'src/app/store/state/state.query';
import { UserAccountAction } from 'src/app/store/userAccount/user-account.action';
import { UserAccountQuery } from 'src/app/store/userAccount/user-account.query';
import { VendorActions } from 'src/app/store/vendor/vendor.action';
import {
  IVendorLocation,
  IVendorOnboardingResult,
  IVendorOnboardingSave,
  mockVendorLocation
} from 'src/app/store/vendor/vendor.model';
import { VendorQuery } from 'src/app/store/vendor/vendor.query';
import {
  OktaService,
  LoggerDatadogService,
  InteractionsApi,
  IdentityApi,
  MapboxService,
  parseAddress
} from 'sustainment-component';
import {
  ICapabilitytProcess,
  ICapability,
  SupplierListModel,
  VendorCreateRequest,
  IVendorProcess,
  IVendorIndustry,
  Organization
} from 'sustainment-component';
import { OnboardingAPI } from 'src/app/api/onboarding.api';
import { AuthService } from 'src/app/services/auth.service';
import { EMPTY_GUID } from 'src/app/utils/contants';
@Component({
  selector: 'app-registration-container',
  styleUrls: ['./registration.container.scss'],
  templateUrl: './registration.container.html',
  providers: [ConfirmationService],
  standalone: false
})
export class RegistrationContainerComponent implements OnInit, OnDestroy {
  public vendorInfo: Partial<IVendorLocation>;

  public processes: ICapabilitytProcess[] = [];
  public industries: ICapabilitytProcess[] = [];
  public preloadVendor$: Observable<SupplierListModel | null>;
  public preloadVendor: SupplierListModel | null;
  public loaded = false;
  public isSaved = false;
  public isNotBuyer = false;
  public sustainmentid: string;

  public originalProcesses: ICapabilitytProcess[] = [];

  private _subscriptions = new Subscription();
  private _localStorageVariable = 'vendor-info';
  private _refCode$: Observable<string | null>;
  public refCode: string | null;
  public autoCompleteOnboarding = false;

  public subprocessSet: Set<string> = new Set<string>();
  public userRoles$: Observable<import('sustainment-component').UserRole[]>;

  // Add signals to track registration states
  private registrationComplete = signal<boolean>(false);
  private navigationTarget = signal<string | null>(null);
  private storeUpdated = signal<boolean>(false);

  constructor(
    private capabilityActions: CapabilityActions,
    private userAccountAction: UserAccountAction,
    private userAccountQuery: UserAccountQuery,
    private okta: OktaService,
    private vendorActions: VendorActions,
    private stateQuery: StateQuery,
    private stateAction: StateActions,
    private vendorQuery: VendorQuery,
    private router: Router,
    private capabilityQuery: CapabilityQuery,
    private interactionsApi: InteractionsApi,
    private identityApi: IdentityApi,
    public loggerDatadogService: LoggerDatadogService,
    private confirmationService: ConfirmationService,
    private _onboardingApi: OnboardingAPI,
    private _authService: AuthService,
    private _mapSvc: MapboxService
  ) {
    // Set up effect to handle navigation when registration is complete
    effect(() => {
      const isComplete = this.registrationComplete();
      const target = this.navigationTarget();
      const isStoreUpdated = this.storeUpdated();

      if (isComplete && target && isStoreUpdated) {
        this.router.navigate([target], { replaceUrl: true });
      }
    });
  }

  public ngOnInit(): void {
    // Set up user roles observable first to ensure it's available
    this.userRoles$ = this.identityApi.getUserRoles().pipe(
      filter((roles) => !!roles && roles.length > 0),
      shareReplay(1)
    );

    this.stateAction.getStateData();
    this.loadIndustryAndProcesess();

    // Check for sustainmentId and handle if it's a temporary one or empty GUID
    const existingSustainmentId = this.userAccountQuery.sustainmentId;
    if (!existingSustainmentId || existingSustainmentId === EMPTY_GUID) {
      // Set up a combined loading check - wait for user roles before showing the component
      this._subscriptions.add(
        this.userRoles$.pipe(first()).subscribe(() => {
          console.log('User roles loaded, enabling registration component');
          this.loaded = true;
        })
      );
      return;
    }

    // For existing users with a sustainmentId
    this.vendorActions.getVendorData();

    // Wait for both vendor data and user roles to be loaded
    this._subscriptions.add(
      combineLatest([
        this.vendorQuery.select((v) => v.vendor).pipe(first()),
        this.userRoles$.pipe(first())
      ]).subscribe(([vendor, roles]) => {
        if (vendor?.name && this.router.url.includes('registration')) {
          this.isSaved = true;
          this.router.navigate(['/dashboard']);
        } else {
          this.loaded = true; // Only set loaded when we have both vendor data and roles
        }
      })
    );

    this._refCode$ = from(this.okta.widget.authClient.getUser()).pipe(
      map((user) => user.ref?.toString() || null)
    );

    const invite$ = this._refCode$.pipe(
      mergeMap((code) =>
        code ? this.interactionsApi.getOdysseus(code.toString()) : of(null)
      )
    );

    this._subscriptions.add(
      this._refCode$.subscribe((code) => (this.refCode = code))
    );

    this.preloadVendor$ = invite$.pipe(
      catchError(() => of(null)),
      tap(
        (odysseus: { stillValid?: boolean; sustainmentId: string } | null) => {
          if (odysseus && !odysseus.stillValid)
            this.confirmationService.confirm({
              key: 'cancelOnboardingPreselection',
              header: 'Register your own business',
              message:
                'This invite seems to have already been used. ' +
                'You can register your business to connect and work with others. ' +
                'If you do not wish to proceed, please close the following window.',
              acceptLabel: 'Create my profile',
              acceptIcon: 'null',
              rejectVisible: false,
              accept: () => {
                this.preloadVendor$ = of(null);
              }
            });
        }
      ),
      mergeMap(
        (result: { stillValid?: boolean; sustainmentId: string } | null) =>
          result?.stillValid
            ? this._onboardingApi.getOpensearchVendor(result.sustainmentId)
            : of(null)
      ),
      catchError(() => of(null))
    );

    this._subscriptions.add(
      this._refCode$
        .pipe(
          mergeMap(() => this.preloadVendor$),
          tap((preload) => {
            this.preloadVendor = preload;
            if (this.refCode && preload) {
              this.vendorInfo = this.mapSupplierListToVendorLocation(preload);

              this._mapSvc
                .getGeocodingWithType(
                  this.vendorInfo.primaryAddress as string,
                  'address',
                  true
                )
                .subscribe((r) => {
                  const mapSearchRes = r?.features[0];
                  const parsedAddress = mapSearchRes?.place_name
                    ? parseAddress(mapSearchRes)
                    : null;

                  if (parsedAddress && this.stateQuery.getValue().states) {
                    this.vendorInfo.address = {
                      ...parsedAddress,
                      stateId: Number(
                        this.stateQuery.getByNameOrAbbreviation(
                          parsedAddress.stateAbbreviation || 'OU'
                        )?.stateId
                      )
                    };
                  }

                  this.loaded = true;
                });
              this.autoCompleteOnboarding = true;
            } else {
              this.loaded = true;
            }
          })
        )
        .subscribe()
    );

    const storageSaved = localStorage.getItem(this._localStorageVariable);
    const sustainmentId = this.userAccountQuery.sustainmentId;
    if (storageSaved) {
      this.vendorInfo = JSON.parse(storageSaved);
      this.loggerDatadogService.info('onboarding resumed', {
        sustainmentId,
        data: this.vendorInfo,
        action: 'onboarding'
      });
    } else {
      this.loggerDatadogService.info('started onboarding from scratch', {
        sustainmentId,
        action: 'onboarding'
      });
      this.vendorInfo = { ...mockVendorLocation };
    }
  }

  public logout(): void {
    this._authService.logout();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public onFinishRegistration(
    data: IVendorOnboardingResult,
    roles?: { id: number; text: string }[],
    problems?: { id: number; text: string }[]
  ): void {
    if (this.preloadVendor) {
      data.sustainmentId = this.preloadVendor.sustainmentId;
    }

    this.preloadVendor$ = of(null);
    this.loaded = false;

    // Reset navigation signals
    this.registrationComplete.set(false);
    this.navigationTarget.set(null);
    this.storeUpdated.set(false);

    // Prepare vendor data
    const createVendor = this.prepareVendorData(data);

    // Execute the registration process
    this.executeRegistrationFlow(createVendor, roles, problems);
  }

  /**
   * Prepare vendor data for the registration API calls
   */
  private prepareVendorData(
    data: IVendorOnboardingResult
  ): VendorCreateRequest {
    const industries = data.industries.map((i) => ({
      id: i.industry.id,
      orderIndex: 0,
      isFavorite: i.isFavorite || false
    }));

    let processes = data.processes;

    if (processes?.length > 0) {
      processes = processes.map((p) => {
        const process = p as { code: string; name: string; isNew: boolean };

        return {
          processCode: process.code,
          name: process.name,
          isCustom: process.isNew
        };
      });
    }

    const createVendor: VendorCreateRequest = {
      ...data,
      primaryAddress: {
        ...data.primaryAddress,
        vendorAddressId: 0,
        unit: '',
        stateId: 0
      },
      industries,
      processes: processes as { id: number; orderIndex: number }[]
    };

    const vendorState = this.stateQuery.getByNameOrAbbreviation(
      data.primaryAddress.state || 'OU'
    );

    if (vendorState) createVendor.primaryAddress.stateId = vendorState?.stateId;

    return createVendor;
  }

  /**
   * Execute the registration flow with prepared vendor data
   */
  private executeRegistrationFlow(
    createVendor: VendorCreateRequest,
    roles?: { id: number; text: string }[],
    problems?: { id: number; text: string }[]
  ): void {
    // Step 1: Create organization first
    this.identityApi
      .registerOrganization(createVendor.name, createVendor.description)
      .pipe(
        // Step 2: Get user account
        mergeMap(() => this.getUserAccount()),
        tap((userAccount) => {
          // Update the user account to ensure we have the most recent data
          this.userAccountAction.setUserAccount(userAccount);
        }),
        // Filter to make sure we have organizations
        filter(
          (userAccount) =>
            !!userAccount.organizations && userAccount.organizations.length > 0
        ),
        // Step 3: Create vendor
        switchMap((userAccount) =>
          this.createVendor(userAccount, createVendor)
        ),
        tap(() => {
          this.userAccountAction.showedWelcome();
        }),
        // Step 4 & 5: Process invitations and login
        mergeMap(() => this.processInvitationsAndLogin(createVendor))
      )
      .subscribe({
        next: (userSession) => {
          this.finalizeRegistration(createVendor, userSession, roles, problems);

          // Set up store update detection
          this.setupStoreUpdateDetection(
            createVendor.isBuyer ? '/home' : '/dashboard'
          );
        },
        error: (err) => {
          console.error('Registration error:', err);
          this.loaded = true; // Ensure loading state is reset on error
        }
      });
  }

  /**
   * Get the user account
   */
  private getUserAccount() {
    return this.identityApi.getUserAccount({
      username: this.userAccountQuery.getValue().userAccount.userName,
      firstName: this.userAccountQuery.getValue().userAccount.firstName,
      lastName: this.userAccountQuery.getValue().userAccount.lastName
    });
  }

  /**
   * Create the vendor with the organization's sustainmentId
   */
  private createVendor(userAccount: any, createVendor: VendorCreateRequest) {
    const organizationSustainmentId =
      userAccount.organizations[0].sustainmentId;

    // Update the createVendor object with the correct sustainmentId
    createVendor.sustainmentId = organizationSustainmentId;

    // Create the vendor with the organization's sustainmentId
    return this.vendorActions.createVendorV3(createVendor, true);
  }

  /**
   * Process invitations and login with the organization sustainmentId
   */
  private processInvitationsAndLogin(createVendor: VendorCreateRequest) {
    // Get organizationSustainmentId from the userAccount
    const organizationSustainmentId =
      this.userAccountQuery.getValue().userAccount.organizations[0]
        .sustainmentId;

    // Process invitations if any
    if (this._refCode$) {
      this._refCode$
        .pipe(
          first(),
          mergeMap((code) => {
            if (code) {
              return this.interactionsApi.acceptInvitation(
                code,
                organizationSustainmentId
              );
            }
            if (organizationSustainmentId) {
              return this.interactionsApi.checkOtherInvitations(
                organizationSustainmentId
              );
            }
            return EMPTY;
          })
        )
        .subscribe({
          next: (result) => {
            this.loggerDatadogService.info('invitation accepted', {
              data: createVendor,
              action: 'onboarding'
            });
          },
          error: (error) => {
            this.loggerDatadogService.error('failed to accept invitation', {
              data: createVendor,
              action: 'onboarding',
              error
            });
          }
        });
    }

    // Login with the correct organization sustainmentId
    return this.identityApi.login(organizationSustainmentId);
  }

  /**
   * Finalize the registration process
   */
  private finalizeRegistration(
    createVendor: VendorCreateRequest,
    userSession: any,
    roles?: { id: number; text: string }[],
    problems?: { id: number; text: string }[]
  ): void {
    // Update user session if available
    if (userSession) {
      this.userAccountAction.setUserSession(userSession);
    }

    // Update user roles if provided
    if (roles?.length) {
      this.identityApi
        .updateUserRoles(
          this.userAccountQuery.sustainmentId,
          roles.map((e) => e.id)
        )
        .subscribe();
      this.userAccountAction.updateUserRoles(roles.map((e) => e.text));
    }

    // Update user problems if provided
    if (problems?.length) {
      this.userAccountAction.updateUserProblems(problems);
    }

    // Mark registration as completed
    this.loaded = true;
    this.isSaved = true;
    this.isNotBuyer = createVendor.isSupplier || createVendor.isOther;
    this.sustainmentid = this.userAccountQuery.sustainmentId;

    // Clean up localStorage
    localStorage.removeItem(this._localStorageVariable);
    localStorage.removeItem('registrationFormData');

    // Mark registration as completed
    localStorage.setItem('registrationCompleted', 'true');

    // Update vendor name in store to satisfy the registration guard check
    const vendor = this.vendorQuery.getValue()?.vendor;
    if (vendor) {
      vendor.name = createVendor.name;
    }
  }

  /**
   * Set up detection of store updates to trigger navigation when ready
   */
  private setupStoreUpdateDetection(targetRoute: string): void {
    console.log(
      'Setting up store update detection for navigation to:',
      targetRoute
    );

    // Set the navigation target
    this.navigationTarget.set(targetRoute);

    // Monitor store changes in the vendor query
    this._subscriptions.add(
      this.vendorQuery
        .select()
        .pipe(skip(1), first())
        .subscribe(() => {
          this.storeUpdated.set(true);
          this.registrationComplete.set(true);
        })
    );

    // Fallback timer in case store doesn't update
    this._subscriptions.add(
      timer(1000).subscribe(() => {
        if (!this.storeUpdated()) {
          this.storeUpdated.set(true);
          this.registrationComplete.set(true);
        }
      })
    );
  }

  public loadIndustryAndProcesess(): void {
    if (!this.vendorInfo || !this._authService.isAuthenticated$) {
      console.warn(
        'Vendor info or authentication is missing. Skipping industry and process loading.'
      );
      return;
    }

    this.capabilityActions.getCapabilityData();
    this._subscriptions.add(
      this.capabilityQuery.select().subscribe((capabilities) => {
        this.originalProcesses = capabilities.processList;
        this.processes =
          capabilities.processList?.map((process) => {
            const using = this.vendorInfo.processes?.find(
              (vendorProcess) => vendorProcess.process.code === process.code
            );

            const subprocessListTemp: ICapability[] = [];
            process.subprocess?.forEach((subprocess: ICapability) => {
              const tempSubprocess: ICapability = {
                name: subprocess.name,
                code: subprocess.code,
                description: subprocess.description,
                using: this.subprocessSet.has(subprocess.code),
                id: subprocess.id
              };
              subprocessListTemp.push(tempSubprocess);
            });

            return {
              name: process.name,
              code: process.code,
              description: process.description,
              using: !!using,
              favorite: !!using && using.isFavorite,
              id: process.id,
              subprocess: subprocessListTemp
            };
          }) || [];

        this.industries =
          capabilities.industryList?.map((industry) => {
            const using = this.vendorInfo.industries?.find(
              (vendorIndustry) => vendorIndustry.industry.code === industry.code
            );
            return {
              name: industry.name,
              code: industry.code,
              description: industry.description,
              using: !!using,
              favorite: !!using && using.isFavorite,
              id: industry.id
            };
          }) || [];
      })
    );
  }

  public updateVendorInfo(data: IVendorOnboardingSave): void {
    this.userAccountAction.updateOrganizationName(data.name);
    const vendorInfoJson = this.getJsonVendor(data);
    localStorage.setItem(this._localStorageVariable, vendorInfoJson);
  }

  public cancelPreselection(): void {
    this.confirmationService.confirm({
      key: 'cancelOnboardingPreselection',
      header: 'Register your own business',
      message:
        'You can register your own business to connect and work with others. ' +
        'If you do not wish to proceed, please close the following window.',
      acceptLabel: 'Create my profile',
      acceptIcon: 'null',
      rejectVisible: false,
      accept: () => {
        this.preloadVendor$ = of(null);
      }
    });
  }

  public requestToJoinCompany(company: SupplierListModel): void {
    if (!company.isRegistered) return;
    this.identityApi.requestToJoinCompany(company.sustainmentId).subscribe(() =>
      this.router.navigateByUrl('/pending-activation', {
        state: { companyName: company.name }
      })
    );
  }

  private getJsonVendor(data: IVendorOnboardingSave): string {
    // Ensure we're saving the state ID along with other address data
    const formSaved = {
      name: data.name,
      address: {
        ...data.address,
        // Make sure stateId is explicitly included
        stateId:
          data.address?.stateId ||
          this.stateQuery.getByNameOrAbbreviation(data.address?.state || '')
            ?.stateId
      },
      processes: [],
      industries: [],
      website: data.website
    };

    return JSON.stringify(formSaved);
  }

  private mapSupplierListToVendorLocation(
    supplier: SupplierListModel
  ): Partial<IVendorLocation> {
    return {
      sustainmentId: supplier.sustainmentId,
      name: supplier.name,
      descriptionPlaceholder: supplier.description,
      primaryAddress:
        supplier.address.addressLine +
        ', ' +
        supplier.address.city +
        ', ' +
        supplier.address.state +
        ' ' +
        supplier.address.zip,

      website: supplier.website,
      processes: supplier.processes as unknown as IVendorProcess[],
      industries: supplier.industries as unknown as IVendorIndustry[]
    };
  }
}
