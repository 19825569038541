import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseVendorAPI, IStateData } from 'sustainment-component';

@Injectable()
export class StateAPI extends BaseVendorAPI {
  private _relativeUrl = 'state';

  public getStateData(): Observable<IStateData[]> {
    return this.get<IStateData[]>(this._relativeUrl);
  }
}
