import { CommonModule } from '@angular/common';
import {
  Component,
  input,
  output,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { UserProblem } from 'sustainment-component';

@Component({
  selector: 'app-user-problems',
  imports: [CommonModule, CheckboxModule, FormsModule],
  templateUrl: './user-problems.component.html',
  styleUrl: './user-problems.component.scss'
})
export class UserProblemsComponent implements OnInit, OnChanges {
  public problems = input.required<UserProblem[]>();
  public savedProblems = input<{ id: number; text: string }[]>([]);
  public enableContinue = output<boolean>();
  public problemSelected = output<{ id: number; text: string }[]>();

  public selectedProblems: Record<number, { selected: boolean; text: string }> =
    {};

  ngOnInit(): void {
    this.initializeSelectedProblems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['savedProblems'] && changes['savedProblems'].currentValue) {
      this.initializeSelectedProblems();
    }
  }

  private initializeSelectedProblems(): void {
    // Initialize selected problems from saved problems
    if (this.savedProblems() && this.savedProblems().length > 0) {
      // Reset existing selections
      this.selectedProblems = {};

      // Re-initialize from saved problems
      this.savedProblems().forEach((problem) => {
        const problemObj = this.problems().find(
          (p) => p.userProblemId === problem.id
        );
        this.selectedProblems[problem.id] = {
          selected: true,
          text:
            problem.text ||
            (problemObj?.description ? problemObj.name || '' : '')
        };
      });

      // Emit to parent that we have problems selected
      this.enableContinue.emit(true);
      this.problemSelected.emit(this.savedProblems());
    }
  }

  public onCompanyBuyerChanged(event: Event, roleId: number): void {
    event.stopPropagation();
    if (this.selectedProblems[roleId]) {
      this.selectedProblems[roleId].selected =
        !this.selectedProblems[roleId].selected;
    } else {
      const problem = this.problems().find((x) => x.userProblemId === roleId);
      this.selectedProblems[roleId] = {
        selected: true,
        text: problem?.description ? problem?.name || '' : ''
      };
    }
    const selectedRoles = Object.entries(this.selectedProblems)
      .filter(([, value]) => value.selected === true)
      .map(([key, value]) => ({ id: +key, text: value.text }));
    this.enableContinue.emit(!!selectedRoles.length);
    this.problemSelected.emit(selectedRoles);
  }

  public textChange(): void {
    const selectedRoles = Object.entries(this.selectedProblems)
      .filter(([, value]) => value.selected === true)
      .map(([key, value]) => ({ id: +key, text: value.text }));
    this.problemSelected.emit(selectedRoles);
  }
}
