import {
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
  OnDestroy,
  AfterViewChecked,
  effect,
  signal
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, filter, take } from 'rxjs/operators';
import {
  IVendorLocation,
  IVendorOnboardingResult,
  IVendorOnboardingSave
} from 'src/app/store/vendor/vendor.model';
import { LoggerDatadogService, UserRole } from 'sustainment-component';
import {
  ICapabilitytProcess,
  ICapability,
  SupplierListModel
} from 'sustainment-component';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as ManufacturingHelper from 'sustainment-component';
import { OnboardingAPI } from 'src/app/api/onboarding.api';
import { Title } from '@angular/platform-browser';
import { regex } from 'sustainment-component';
import { UserAccountQuery } from 'src/app/store/userAccount/user-account.query';
import { StateQuery } from 'src/app/store/state/state.query';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

export enum RegistrationSteps {
  CompanyDetails,
  CompanySuggestions,
  CompanyTypeRegistration,
  UserProblems,
  WelcomeScreen
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  standalone: false
})
export class RegistrationComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input() public vendorInfo: Partial<IVendorLocation>;
  @Input() public processes: ICapabilitytProcess[];
  @Input() public industries: ICapability[];
  @Input() public autoCompleteOnboarding = false;
  public userRolesSignal = signal<UserRole[]>([]);
  @Input() set userRolesData(value: UserRole[]) {
    if (value && value.length > 0) {
      this.userRolesSignal.set(value);
      this.userRolesSubject.next(value);
      this.userRolesLoading = false;
    }
  }

  get userRolesData(): UserRole[] {
    return this.userRolesSignal();
  }

  public userRoles = input<UserRole[]>([]);

  @Output() public logout = new EventEmitter<void>();
  @Output() public patchVendor = new EventEmitter<{
    vendor: IVendorOnboardingResult;
    roles: { id: number; text: string }[];
    problems: { id: number; text: string }[];
  }>();
  @Output() public vendorUpdated = new EventEmitter<IVendorOnboardingSave>();
  @Output() public requestedToJoin = new EventEmitter<SupplierListModel>();

  public vendorSelected: SupplierListModel | null;
  public form = new FormGroup({
    name: new FormControl('', [Validators.maxLength(75), Validators.required]),
    address: new FormControl('', [
      Validators.maxLength(200),
      Validators.required
    ]),
    address2: new FormControl('', [Validators.maxLength(200)]),
    city: new FormControl('', [Validators.maxLength(200), Validators.required]),
    state: new FormControl(''),
    zip: new FormControl(''),
    county: new FormControl(''),
    country: new FormControl(''),
    latitude: new FormControl<number | null>(null),
    longitude: new FormControl<number | null>(null),
    website: new FormControl('', [
      Validators.pattern(regex.websiteUrlValidatorPattern)
    ])
  });
  public disableContinue = true;
  public loadCompaniesSuggestion = false;

  public suggestedVendors: SupplierListModel[] = [];

  public steps = RegistrationSteps;
  public currentStep = this.steps.CompanyDetails;
  public isInternationalCompany: boolean;
  public internationalAcknowledgmentAccepted: boolean;
  public selectedProblems: ManufacturingHelper.UserProblem[] = [];

  public roles: { id: number; text: string }[] = [];
  public problems: { id: number; text: string }[];
  public userRolesLoading = true;
  public userRoles$: Observable<UserRole[]>;

  private rolesSubject = new BehaviorSubject<{ id: number; text: string }[]>(
    []
  );
  private userRolesSubject = new BehaviorSubject<UserRole[]>([]);
  private subscription = new Subscription();
  private rolesInitialized = false;

  public constructor(
    public loggerDatadogService: LoggerDatadogService,
    private _stateQuery: StateQuery,
    private onboardingApi: OnboardingAPI,
    private titleService: Title,
    private _userAccountQuery: UserAccountQuery
  ) {
    this.titleService.setTitle('Set up your account - Sustainment Portal');

    effect(() => {
      const userRoles = this.userRolesSignal();
      if (userRoles && userRoles.length > 0) {
        // If we're on the CompanyTypeRegistration step, calculate problems
        if (this.currentStep === this.steps.CompanyTypeRegistration) {
          this.calculateSelectedProblems();
        }
      }
    });
  }

  private readonly _localStorageKey = 'registrationFormData';

  public ngOnInit(): void {
    // Load saved registration data from local storage
    const savedData = localStorage.getItem(this._localStorageKey);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      this.vendorInfo = parsedData.vendorInfo || {};
      this.currentStep = parsedData.currentStep || this.steps.CompanyDetails;
      this.roles = parsedData.roles || [];
      this.problems = parsedData.problems;
      this.rolesSubject.next(this.roles);

      // Restore selected vendor if available
      if (parsedData.vendorSelected) {
        this.vendorSelected = parsedData.vendorSelected;
      }

      // Restore form values if available
      if (parsedData.formValues) {
        this.form.patchValue(parsedData.formValues);
      }
    }

    // Initialize vendor info if not available
    if (!this.vendorInfo) {
      this.vendorInfo = {};
    }

    this.setForm();

    if (this.autoCompleteOnboarding) {
      this.currentStep = this.steps.CompanyTypeRegistration;
    }

    // Setup the observable for user roles
    this.userRoles$ = this.userRolesSubject
      .asObservable()
      .pipe(filter((roles) => !!roles && roles.length > 0));

    // Subscribe to the userRoles$ to track when they're loaded
    this.subscription.add(
      this.userRoles$.subscribe((roles) => {
        this.userRolesLoading = false;
        this.userRolesData = roles;
        this.calculateSelectedProblems();
      })
    );

    // Set up the user roles subject with initial data
    const initialRoles = this.getUserRoles();
    if (initialRoles && initialRoles.length > 0) {
      this.userRolesSubject.next(initialRoles);
      this.userRolesLoading = false;
    }

    // Subscribe to form value changes to save in real time
    this.subscription.add(
      this.form.valueChanges.subscribe(() => {
        if (this.form.valid || this.form.dirty) {
          this.saveFormDataToLocalStorage();
        }
      })
    );

    // Set up the reactive flow for calculating selected problems
    this.setupSelectedProblemsReaction();
  }

  public ngAfterViewChecked(): void {
    // Check if we need to calculate selected problems after roles have loaded
    if (
      !this.rolesInitialized &&
      !this.userRolesLoading &&
      this.getUserRoles().length > 0
    ) {
      this.rolesInitialized = true;
      this.calculateSelectedProblems();

      // If current step is CompanyTypeRegistration and we have problems, move to the next step
      if (
        this.currentStep === this.steps.CompanyTypeRegistration &&
        this.selectedProblems.length
      ) {
        // Use setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
          this.currentStep = this.steps.UserProblems;
          this.saveFormDataToLocalStorage();
        });
      }
    }
  }

  private setupSelectedProblemsReaction(): void {
    // This subscription will trigger whenever roles change
    this.subscription.add(
      this.rolesSubject.subscribe(() => {
        this.calculateSelectedProblems();
      })
    );
  }

  private calculateSelectedProblems(): void {
    const userRoles = this.getUserRoles();

    if (
      userRoles &&
      userRoles.length > 0 &&
      this.roles &&
      this.roles.length > 0
    ) {
      this.updateSelectedProblems(userRoles);
    }
  }

  private updateSelectedProblems(userRoles: UserRole[]): void {
    // Only proceed if we have both userRoles and this.roles
    if (!userRoles?.length || !this.roles?.length) {
      return;
    }

    const selectedRoleIds = this.roles.map((e) => e.id);

    this.selectedProblems = userRoles
      .filter((role: UserRole) => selectedRoleIds.includes(role.userRoleId))
      .reduce((acc: ManufacturingHelper.UserProblem[], role: UserRole) => {
        role.problems.forEach((problem: ManufacturingHelper.UserProblem) => {
          if (!acc.some((p) => p.userProblemId === problem.userProblemId)) {
            acc.push(problem);
          }
        });
        return acc;
      }, [])
      .sort(
        (
          a: ManufacturingHelper.UserProblem,
          b: ManufacturingHelper.UserProblem
        ) => a.userProblemId - b.userProblemId
      );
  }

  public setForm(): void {
    if (!this.vendorInfo) {
      console.warn('No vendor info available. Skipping form setup.');
      return;
    }
    const vendorAddress = this.vendorInfo?.address;

    const patchFormWithState = () => {
      let state = '';
      if (vendorAddress?.stateId) {
        // Search by stateId if available
        const stateById = this._stateQuery.getByStateId(
          Number(vendorAddress.stateId)
        );
        state = stateById?.abbreviation || '';
      } else if (vendorAddress?.state) {
        // If no stateId but state name/abbreviation is available
        const stateByName = this._stateQuery.getByNameOrAbbreviation(
          vendorAddress.state
        );
        state = stateByName?.abbreviation || vendorAddress.state;
      }

      // Ensure the state value is properly patched into the form
      this.form.patchValue({
        name: this.vendorInfo?.name || null,
        website: this.vendorInfo?.website || null,
        address: vendorAddress?.address || '',
        address2: vendorAddress?.address2 || '',
        city: vendorAddress?.city || '',
        state: state || '', // Ensure state is patched correctly
        zip: vendorAddress?.postalCode || '',
        county: vendorAddress?.county || '',
        country: vendorAddress?.country || 'US',
        latitude: vendorAddress?.latitude || null,
        longitude: vendorAddress?.longitude || null
      });

      // If we've restored roles from localStorage, make sure the form state reflects this
      if (this.roles && this.roles.length > 0) {
        this.disableContinue = false;
      }
    };

    // Check if states are already loaded
    if (this._stateQuery.getValue()?.states) {
      patchFormWithState();
    } else {
      // Wait for states to load and then patch the form
      const subscription = this._stateQuery
        .select((state) => state.states)
        .subscribe((states) => {
          if (states) {
            patchFormWithState();
            subscription.unsubscribe();
          }
        });
    }
  }

  public emitVendorStateUpdate(): void {
    this.vendorUpdated.emit({
      name: this.form.value.name || '',
      website: this.form.value.website || '',
      processes: [],
      industries: [],
      address: {
        address: this.form.value.address || '',
        address2: this.form.value.address2 || '',
        city: this.form.value.city || '',
        stateId:
          this._stateQuery.getByNameOrAbbreviation(this.form.value.state || '')
            ?.stateId || 143,
        postalCode: this.form.value.zip || '',
        county: this.form.value.county || '',
        country: this.form.value.country || '',
        unit: ''
      }
    });
  }

  public onClickBack(): void {
    if (this.currentStep === this.steps.UserProblems) {
      this.disableContinue = true;
    }
    if (this.currentStep !== this.steps.CompanyTypeRegistration) {
      this.currentStep -= 1;
    } else {
      this.currentStep =
        this.suggestedVendors.length > 0
          ? this.steps.CompanySuggestions
          : this.steps.CompanyDetails;
    }

    this.emitVendorStateUpdate();
  }

  public onContinueClick(): void {
    if (!this.vendorInfo) {
      // Initialize empty vendor info instead of skipping
      console.warn('Vendor info is missing. Initializing empty vendor info.');
      this.vendorInfo = {};
    }

    // Save current form data to local storage before proceeding
    this.saveFormDataToLocalStorage();

    this.disableContinue = true;
    switch (this.currentStep) {
      case this.steps.CompanyDetails: {
        this.emitVendorStateUpdate();

        const country = this.form.controls.country.value || '';

        this.loadCompaniesSuggestion = true;
        this.vendorInfo.name = this.form.value.name!;

        // Check if we have a sustainmentId to make the API call
        const hasSustainmentId =
          this._userAccountQuery.getValue()?.sustainmentId;

        if (!hasSustainmentId) {
          // Skip API call if no sustainmentId, proceed directly to next step
          this.suggestedVendors = [];
          this.currentStep = this.steps.CompanyTypeRegistration;
          this.logVendorStep();
          this.saveFormDataToLocalStorage();
          this.loadCompaniesSuggestion = false;
          break;
        }

        this.onboardingApi
          .getOpensearchResults({
            companyName: this.form.value.name || '',
            state: country == 'US' ? this.form.controls.state.value || '' : '',
            website: this.getWebsiteDomain(this.form.value.website || ''),
            country: country == 'US' ? '' : country
          })
          .pipe(map((x) => x))
          .subscribe((results) => {
            if (results) {
              results.forEach((vendor) => {
                if (vendor && vendor && vendor.processes)
                  vendor.processes =
                    ManufacturingHelper.mapVendorProfileProcessSubprocess(
                      vendor,
                      this.processes
                    );
              });

              this.suggestedVendors = results;
              this.currentStep =
                this.suggestedVendors.length > 0
                  ? this.steps.CompanySuggestions
                  : this.steps.CompanyTypeRegistration;
              this.logVendorStep();

              // Save current step to local storage
              this.saveFormDataToLocalStorage();

              if (this.suggestedVendors.length === 0) {
                this.onNoneClicked();
              }
              this.loadCompaniesSuggestion = false;
            }
          });
        break;
      }
      case this.steps.CompanySuggestions:
        if (this.vendorSelected?.isRegistered) {
          this.requestedToJoin.emit(this.vendorSelected);
        } else {
          this.currentStep = this.steps.CompanyTypeRegistration;
          this.saveFormDataToLocalStorage();
        }
        break;

      case this.steps.CompanyTypeRegistration:
        if (this.userRolesLoading) {
          this.disableContinue = false;
          return;
        }

        this.selectedProblems = this.getUserRoles()
          .filter((role: UserRole) =>
            this.roles.map((e) => e.id).includes(role.userRoleId)
          )
          .reduce((acc: ManufacturingHelper.UserProblem[], role: UserRole) => {
            role.problems.forEach(
              (problem: ManufacturingHelper.UserProblem) => {
                if (
                  !acc.some((p) => p.userProblemId === problem.userProblemId)
                ) {
                  acc.push(problem);
                }
              }
            );
            return acc;
          }, [])
          .sort(
            (
              a: ManufacturingHelper.UserProblem,
              b: ManufacturingHelper.UserProblem
            ) => a.userProblemId - b.userProblemId
          );
        if (this.selectedProblems.length) {
          this.currentStep = this.steps.UserProblems;
          this.saveFormDataToLocalStorage();
        } else {
          this.saveVendor();
        }
        break;
      case this.steps.UserProblems:
        this.saveVendor();
        break;
      default:
        break;
    }
  }

  private getWebsiteDomain(website: string): string {
    if (!website) return '';

    website = website.replace(/\s+/g, '');

    if (!website.startsWith('http://') && !website.startsWith('https://')) {
      website = 'http://' + website;
    }

    const parsedUrl = new URL(website);
    return parsedUrl.host;
  }

  public enableContinue(event: SupplierListModel | boolean): void {
    this.disableContinue = !event;

    if (
      this.isInternationalCompany &&
      !this.internationalAcknowledgmentAccepted
    )
      this.disableContinue = true;

    if (typeof event !== 'boolean' && event?.sustainmentId) {
      this.vendorSelected = event;
      this.disableContinue = this.vendorSelected.isSelected ? false : true;
    }
  }

  public onNoneClicked(): void {
    this.vendorSelected = null;
    this.currentStep = this.steps.CompanyTypeRegistration;
  }

  public saveVendor(): void {
    this.disableContinue = true;

    this.emitVendorStateUpdate();

    const vendor: IVendorOnboardingResult = {
      name: this.vendorSelected?.name || this.form.value.name || '',
      primaryAddress: {
        address:
          this.vendorSelected?.address?.addressLine ||
          this.form.value.address ||
          '',
        address2:
          this.vendorSelected?.address.addressLine2 ||
          this.form.value.address2 ||
          '',
        postalCode: this.form.value.zip || '',
        state: this.form.value.state || '',
        latitude:
          this.vendorSelected?.address?.latitude ||
          this.form.value.latitude ||
          null,
        longitude:
          this.vendorSelected?.address?.longitude ||
          this.form.value.longitude ||
          null,
        city: this.vendorSelected?.address?.city || this.form.value.city || '',
        county:
          this.vendorSelected?.address?.county || this.form.value.county || '',
        country: this.form.controls.country.value || ''
      },
      logo: this.vendorSelected?.logo || null,
      website: this.vendorSelected?.website || this.form.value.website || '',
      processes: [],
      industries: [],
      sustainmentId:
        this.vendorSelected?.sustainmentId ||
        this._userAccountQuery.getValue().sustainmentId,
      isBuyer: this.getUserRoles().some(
        (role: UserRole) =>
          role.buyerRole &&
          this.roles.map((e) => e.id).includes(role.userRoleId)
      ),
      isSupplier: this.getUserRoles().some(
        (role: UserRole) =>
          role.supplierRole &&
          this.roles.map((e) => e.id).includes(role.userRoleId)
      ),
      isOther: this.getUserRoles().some(
        (role: UserRole) =>
          role.otherRole &&
          this.roles.map((e) => e.id).includes(role.userRoleId)
      )
    };

    // Save one last time before submitting to ensure all data is persisted
    this.saveFormDataToLocalStorage();

    this.patchVendor.emit({
      vendor,
      roles: this.roles,
      problems: this.problems
    });
  }

  public acknowledgeInternationalAccepted(event: boolean): void {
    this.internationalAcknowledgmentAccepted = event;

    if (this.form.valid) {
      this.disableContinue = !event;
    }
    this.saveFormDataToLocalStorage();
  }

  public internationalCompanyChange(event: boolean): void {
    this.isInternationalCompany = event;
    this.saveFormDataToLocalStorage();
  }

  private logVendorStep(): void {
    this.loggerDatadogService.info(
      `Onboarding step ${this.steps[this.currentStep]}`,
      { data: this.vendorInfo, action: 'onboarding' }
    );
  }

  /**
   * Saves the current form data and registration progress to local storage
   * This allows users to refresh the page and continue registration where they left off
   */
  public saveFormDataToLocalStorage(): void {
    const formData = {
      vendorInfo: {
        ...this.vendorInfo,
        name: this.form.value.name,
        website: this.form.value.website,
        address: {
          address: this.form.value.address,
          address2: this.form.value.address2,
          city: this.form.value.city,
          postalCode: this.form.value.zip,
          county: this.form.value.county,
          country: this.form.value.country,
          state: this.form.value.state,
          stateId: this._stateQuery.getByNameOrAbbreviation(
            this.form.value.state || ''
          )?.stateId,
          latitude: this.form.value.latitude,
          longitude: this.form.value.longitude
        }
      },
      currentStep: this.currentStep,
      roles: this.roles,
      problems: this.problems,
      vendorSelected: this.vendorSelected,
      formValues: this.form.value
    };
    localStorage.setItem(this._localStorageKey, JSON.stringify(formData));
  }

  /**
   * Method to access user roles consistently
   * This replaces the input signal call in the template
   */
  public getUserRoles(): UserRole[] {
    // Use the standard @Input property instead of the signal
    return this.userRolesData || [];
  }

  public selectedRoles(roles: { id: number; text: string }[]): void {
    this.roles = roles;
    this.rolesSubject.next(roles);
    this.saveFormDataToLocalStorage(); // Save changes immediately
  }

  public selectProblems(problems: { id: number; text: string }[]): void {
    this.problems = problems;
    this.saveFormDataToLocalStorage(); // Save changes immediately
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
