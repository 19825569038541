import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  effect,
  signal,
  computed,
  model
} from '@angular/core';
import { UserRole } from 'sustainment-component';

@Component({
  selector: 'app-registration-company-type',
  templateUrl: './company-type.component.html',
  styleUrls: ['./company-type.component.scss'],
  standalone: false
})
export class RegistrationCompanyTypeComponent implements OnInit {
  public roles = model<UserRole[]>([]);
  public savedRoles = model<{ id: number; text: string }[]>([]);
  @Output() public enableContinue = new EventEmitter<boolean>();
  @Output() public roleSelected = new EventEmitter<
    { id: number; text: string }[]
  >();

  public selectedRoles = signal<Record<number, boolean>>({});
  public multiselectInvalid: boolean;

  public rolesArray = computed(() => this.roles());

  constructor() {
    effect(() => {
      this.initializeSelectedRoles(this.savedRoles());
    });
  }

  ngOnInit(): void {
    this.initializeSelectedRoles(this.savedRoles());
  }

  private initializeSelectedRoles(
    savedRolesList: { id: number; text: string }[]
  ): void {
    // Reset selected roles
    const newSelectedRoles: Record<number, boolean> = {};

    // If we have saved roles, mark them as selected
    if (savedRolesList && savedRolesList.length > 0) {
      savedRolesList.forEach((role) => {
        newSelectedRoles[role.id] = true;
      });

      // Emit to parent that we have roles selected
      this.enableContinue.emit(true);
    }

    this.selectedRoles.set(newSelectedRoles);
  }

  public onCompanyBuyerChanged(event: Event, roleId: number): void {
    event.stopPropagation();
    const currentRoles = { ...this.selectedRoles() };
    currentRoles[roleId] = !currentRoles[roleId];
    this.selectedRoles.set(currentRoles);

    const selectedRoleIds = Object.entries(currentRoles)
      .filter(([, value]) => value === true)
      .map(([key]) => +key);
    this.enableContinue.emit(!!selectedRoleIds.length);

    const selectedRoles = this.roles().filter((e) =>
      selectedRoleIds.includes(e.userRoleId)
    );

    this.roleSelected.emit(
      selectedRoles.map((e) => {
        return { id: e.userRoleId, text: e.name };
      })
    );
  }

  public isRoleSelected(roleId: number): boolean {
    return this.selectedRoles()[roleId] || false;
  }
}
