import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SupplierListModel } from 'sustainment-component';
@Component({
  selector: 'app-registration-company-suggestion',
  templateUrl: './company-suggestion.component.html',
  styleUrls: ['./company-suggestion.component.scss'],
  standalone: false
})
export class RegistrationCompanySuggestionComponent {
  @Input() public suggestedVendors: SupplierListModel[];

  @Output() public enableContinue = new EventEmitter<SupplierListModel>();

  public onToggleCardSelection(vendor: SupplierListModel): void {
    this.suggestedVendors = this.suggestedVendors.map((v) => {
      return {
        ...v,
        isSelected:
          v.sustainmentId !== vendor?.sustainmentId ? false : vendor?.isSelected
      };
    });

    this.enableContinue.next(vendor);
  }
}
