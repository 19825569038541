import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-claimed-welcome-screen',
    templateUrl: './claimed-welcome-screen.component.html',
    styleUrls: ['./claimed-welcome-screen.component.scss'],
    standalone: false
})
export class ClaimedWelcomeScreenComponent {
  public isReLogin = true;
  public companyName: string;

  public constructor(private router: Router) {
    this.companyName = router.getCurrentNavigation()?.extras.state?.companyName;
    if (!this.companyName) this.router.navigateByUrl('');
  }
}
