import {
  AfterViewInit,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { COUNTRIES, MapboxService, parseAddress } from 'sustainment-component';
import { IGeocodeFeature, ILocation } from 'sustainment-component';
import { RegistrationComponent } from '../registration.component';
import { StateQuery } from 'src/app/store/state/state.query';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-registration-company-details',
    templateUrl: './company-details.component.html',
    styleUrls: ['./company-details.component.scss'],
    standalone: false
})
export class RegistrationCompanyDetailsComponent
  implements OnInit, AfterViewInit
{
  private _stateQuery = inject(StateQuery);
  @Input() public form: InstanceType<typeof RegistrationComponent>['form'];
  @Input() public preLoadedAddress: string | undefined;
  @Output() public enableContinue = new EventEmitter<boolean>();
  @Output() public internationalCompanyChange = new EventEmitter<boolean>();
  @Output() public acknowledgeInternationalCompanyAcceptedChange =
    new EventEmitter<boolean>();

  public countries = COUNTRIES;
  public stateData = toSignal(this._stateQuery.select((state) => state.states));
  public stateAbbreviations = computed(() =>
    (this.stateData() || [])
      .map((state) => state.abbreviation)
      .filter((abbrev) => !!abbrev && abbrev !== 'OU')
      .sort()
  );
  public searchResults$: Observable<IGeocodeFeature[]>;
  public acknowledgeInternationalCompanyAccepted: boolean;
  public isInternationalCompany = signal<boolean>(false);
  public currentlySelectedAddress = signal<IGeocodeFeature | null>(null);
  private _locationSearch = new Subject<string>();

  public constructor(private _mapSvc: MapboxService) {}

  public ngOnInit(): void {
    this.enableContinue.next(this.form.valid);

    if (this.form.value.country !== 'US') this.isInternationalCompany.set(true);

    const proximity: ILocation = {
      // Sustainment US address as proximity
      lat: 35.46800452928232,
      lng: -97.52380002546367
    };

    this.searchResults$ = this._locationSearch.pipe(
      switchMap((location) =>
        this._mapSvc
          .getGeocodingWithType(
            location,
            'address',
            true,
            proximity,
            String(this.form.value.country)
          )
          .pipe(
            map((r) => {
              if (this.preLoadedAddress && r?.features?.length > 0) {
                const currentlySelectedAddress = r?.features[0];
                this.currentlySelectedAddress.set(currentlySelectedAddress);

                if (currentlySelectedAddress) {
                  const parsedAddress = parseAddress(currentlySelectedAddress);

                  this.form.patchValue({
                    ...parsedAddress,
                    zip: parsedAddress.postalCode,
                    state: parsedAddress.stateAbbreviation
                  });
                }
              }

              return r?.features
                .sort((a, b) => {
                  const aIncludesUS = a.place_name.includes('United States');
                  const bIncludesUS = b.place_name.includes('United States');

                  if (aIncludesUS && !bIncludesUS) {
                    return -1;
                  } else if (!aIncludesUS && bIncludesUS) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map((item) => {
                  return {
                    ...item,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    place_name: item.place_name.split(', United States')[0]
                  };
                });
            })
          )
      )
    );

    this.form.valueChanges.subscribe((v) => {
      const stateInput = this.form.controls.state;

      if (v.country !== 'US') {
        stateInput.value && stateInput.patchValue('', { emitEvent: false });
        stateInput.disable({ emitEvent: false });
        this.isInternationalCompany.set(true);
        this.enableContinue.next(
          this.form.valid && this.acknowledgeInternationalCompanyAccepted
        );
      } else {
        stateInput.enable({ emitEvent: false });
        this.isInternationalCompany.set(false);
        this.enableContinue.next(this.form.valid);
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this.preLoadedAddress) {
      this.searchLocation(this.preLoadedAddress);
    }
  }

  public searchLocation(location: string): void {
    this._locationSearch.next(location);
  }

  public onLocationChanged(e: IGeocodeFeature | string): void {
    if (!e) return this.internationalCompanyChange.emit(false);

    const selectedAddress = e;

    if (typeof selectedAddress === 'string') {
      this.form.patchValue({ address: selectedAddress });
    } else {
      const parsedAddress = parseAddress(selectedAddress);

      this.form.patchValue({
        ...parsedAddress,
        latitude: selectedAddress.center[1],
        longitude: selectedAddress.center[0],
        zip: parsedAddress.postalCode,
        state: parsedAddress.stateAbbreviation
      });

      const isUSAddress = parsedAddress.country === 'US';

      this.internationalCompanyChange.emit(!isUSAddress);
      this.isInternationalCompany.set(!isUSAddress);

      if (isUSAddress) {
        this.acknowledgeInternationalCompanyAccepted = false;
        this.acknowledgeInternationalCompanyAcceptedChange.emit(false);
      }

      this.enableContinue.next(this.form.valid);
    }
  }

  public acknowledgmentChange(): void {
    this.acknowledgeInternationalCompanyAccepted =
      !this.acknowledgeInternationalCompanyAccepted;
    this.acknowledgeInternationalCompanyAcceptedChange.emit(
      this.acknowledgeInternationalCompanyAccepted
    );
  }
}
