import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-welcome-screen',
    templateUrl: './welcome-screen.component.html',
    styleUrls: ['./welcome-screen.component.scss'],
    standalone: false
})
export class WelcomeScreenComponent {
  @Input() public id: string;
  public constructor(private router: Router) {}

  public continueToProfile(): void {
    this.router.navigateByUrl(`/profile/${this.id}`);
  }
  public continueToHp(): void {
    this.router.navigateByUrl('/home');
  }
}
